import React, { useState } from 'react';
import { Box, Dialog, FormControl, InputLabel, Select, MenuItem, Button, Grid,
  TextField  } from '@mui/material';
import ReviewHighlights from './ReviewHighlights';
import ReviewTips from './ReviewTips';
import CircularProgress from '@mui/material/CircularProgress';

const createNumberInputGrid = (label, value, min, handleChange, gridSize) => {
  return (
    <Grid item md={gridSize}>
      {createNumberInput(label, value, min, handleChange)}
    </Grid>
  );
};

const createNumberInput = (label, value, min, handleChange, required = true) => {
  return (
    <FormControl key={label} fullWidth variant="outlined">
      <TextField
        type="number"
        size="small"
        sx={{mt:1}}
        label={label}
        value={value}
        onChange={handleChange}
        required={required}
        inputProps={{ min }}
        variant="outlined"
      />
    </FormControl>
  );
}

const ReviewSubmissionValidForm = ({
  videoRef,
  videoAngle,
  setVideoAngle,
  timestampImages,
  setTimestampImages,
  data,
  setData,
  handleValid,
  highlights,
  setHighlights,
  tips,
  setTips,
  isUploading,
}) => {
  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const renderImage = (imageUrl) => {
    return (
      imageUrl && (
        <img
          src={imageUrl}
          alt="Video frame"
          style={{ maxHeight: "400px", cursor: "pointer" }}
          onClick={() => {
            setCurrentImage(imageUrl);
            setOpenImageModal(true);
          }}
        />
      )
    );
  };

  const handleTimestampChange = (view, phase, leg = null) => (event) => {
    event.preventDefault();

    const canvas = document.createElement('canvas');
    canvas.height = videoRef.current.videoHeight;
    canvas.width = videoRef.current.videoWidth;
    const context = canvas.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const imageUrl = canvas.toDataURL('image/webp', 0.8);

    setTimestampImages((prevImages) => ({
      ...prevImages,
      [view]: {
        ...prevImages[view],
        [phase]: leg ? { ...prevImages[view][phase], [leg]: imageUrl } : imageUrl,
      },
    }));
  };

  const areTimestampsSet = () => {
    if (videoAngle === 'side') {
      return ['LR', 'MSt', 'LSt', 'MSw'].every(
        (phase) => timestampImages.side[phase] !== null && timestampImages.side[phase] !== ''
      );
    }
    if (videoAngle === 'back') {
      return ['MSt', 'MSw', 'IC'].every((phase) =>
        ['left', 'right'].every(
          (leg) => timestampImages.back[phase][leg] !== null && timestampImages.back[phase][leg] !== ''
        )
      );
    }
    return false;
  };

  const handleDataChange = (view, phase, parameter, leg = null) => (event) => {
    const rawValue = event.target.value;
    const value = isNaN(parseFloat(rawValue)) ? rawValue : parseFloat(rawValue);
    if (view === 'side') {
      setData((prevState) => ({
        ...prevState,
        side: {
          ...prevState.side,
          [phase]: {
            ...prevState.side[phase],
            [parameter]: value,
          },
        },
      }));
    } else if (view === 'back') {
      setData((prevState) => {
        if (leg !== null) { // When leg-specific data needs to be updated
          return {
            ...prevState,
            back: {
              ...prevState.back,
              [phase]: {
                ...prevState.back[phase],
                [parameter]: {
                  ...prevState.back[phase][parameter],
                  [leg]: value,
                },
              },
            },
          };
        } else { // When data is not leg-specific (baseOfSupport, kneeWindow)
          return {
            ...prevState,
            back: {
              ...prevState.back,
              [phase]: {
                ...prevState.back[phase],
                [parameter]: value,
              },
            },
          };
        }
      });
    }
  };

  const handleCadenceChange = (event) => {
    const value = event.target.value;
    if (value !== '') {
      setData((prevState) => ({
        ...prevState,
        cadence: Math.max(0, value),
      }));
    } else {
      setData((prevState) => ({
        ...prevState,
        cadence: undefined,
      }));
    }
  };

  const handleTreadmillShakingChange = (event) => {
    const value = event.target.value;
    if (value !== '') {
      setData((prevState) => ({
        ...prevState,
        treadmillShakingDegree: Math.max(0, Math.min(5, value)),
      }));
    } else {
      setData((prevState) => ({
        ...prevState,
        treadmillShakingDegree: undefined,
      }));
    }
  };

  return (
    <form onSubmit={handleValid}>
      <Box sx={{mt:1, mb:1}} >
        <FormControl size="small" variant="outlined" fullWidth>
          <InputLabel id="video-angle-label">Video Angle</InputLabel>
          <Select
            labelId="video-angle-label"
            value={videoAngle}
            onChange={(e) => setVideoAngle(e.target.value)}
            required
          >
            <MenuItem value="">
              <em>--Select a video angle--</em>
            </MenuItem>
            <MenuItem value="side">Side View</MenuItem>
            <MenuItem value="back">Back View</MenuItem>
          </Select>
        </FormControl>

        {videoAngle === 'side' && (
          <>
            <fieldset>
              <legend>Images</legend>
              <Grid container spacing={0.5}>
                {['LR', 'MSt', 'LSt', 'MSw'].map((phase) => (
                  <Grid item md={3} key={`${phase}-button`}>
                    <Button fullWidth variant="contained" color="primary" onClick={handleTimestampChange('side', phase)}>
                      Mark {phase} Time
                    </Button>
                  </Grid>
                ))}
                {['LR', 'MSt', 'LSt', 'MSw'].map((phase) => (
                  <Grid item md={3} key={`${phase}-image`}>
                    {renderImage(timestampImages.side[phase])}
                  </Grid>
                ))}
              </Grid>
            </fieldset>

            <fieldset>
              <legend>Side view metrics</legend>
              {createNumberInput(`Foot Strike, 0-5, LR`, data.side.LR.footStrikeAngle, 0, handleDataChange('side', 'LR', 'footStrikeAngle'))}
              {createNumberInput(`Tibia Angle, 85-95, LR`, data.side.LR.tibiaAngle, 0, handleDataChange('side', 'LR', 'tibiaAngle'))}
              {createNumberInput(`Overstride, 0-10, LR`, data.side.LR.degreeOfOverstriding, 0, handleDataChange('side', 'LR', 'degreeOfOverstriding'))}
              {createNumberInput(`Knee Flexion, 45+, MSt`, data.side.MSt.kneeFlexionAngle, 0, handleDataChange('side', 'MSt', 'kneeFlexionAngle'))}
              {createNumberInput(`Heel Height, 90+, MSt`, data.side.MSt.heelHeight, 0, handleDataChange('side', 'MSt', 'heelHeight'))}
              {createNumberInput(`Vertical Displacement, 0-8, MSt-MSw`, data.side.MSt.verticalDisplacement, 0, handleDataChange('side', 'MSt', 'verticalDisplacement'))}
              {createNumberInput(`Trunk Lean, 7-20, LSt`, data.side.LSt.trunkLeanAngle, 0, handleDataChange('side', 'LSt', 'trunkLeanAngle'))}
              {createNumberInput(`Hip Extension, 20+, LSt`, data.side.LSt.hipExtensionAngle, 0, handleDataChange('side', 'LSt', 'hipExtensionAngle'))}
            </fieldset>
          </>
        )}

        {videoAngle === 'back' && (
          <>
            <fieldset>
              <legend>Images</legend>
              <Grid container spacing={0.5}>
                {['left', 'right'].map((leg) =>
                  ['IC', 'MSt', 'MSw'].map((phase) => (
                    <Grid item md={4} key={`${leg}-${phase}-button`}>
                      <Button fullWidth variant="contained" color="primary" onClick={handleTimestampChange('back', phase, leg)}>
                        Mark {phase} Time, {leg}
                      </Button>
                    </Grid>
                  ))
                )}
                {['left', 'right'].map((leg) =>
                  ['IC', 'MSt', 'MSw'].map((phase) => (
                    <Grid item md={4} key={`${leg}-${phase}-image`}>
                      {renderImage(timestampImages.back[phase][leg])}
                    </Grid>
                  ))
                )}
              </Grid>
            </fieldset>
            <fieldset>
              <legend>Back view metrics</legend>
              <Grid container spacing={1}>
                {createNumberInputGrid(`Pelvic Drop, L, 0-5, IC vs MSt`, data.back.IC.pelvicDrop.left, 0, handleDataChange('back', 'IC', 'pelvicDrop', 'left'), 6)}
                {createNumberInputGrid(`Pelvic Drop, R, 0-5, IC vs MSt`, data.back.IC.pelvicDrop.right, 0, handleDataChange('back', 'IC', 'pelvicDrop', 'right'), 6)}

                {createNumberInputGrid(`Heel Eversion, L, 0-10, IC vs MSt`, data.back.IC.heelEversion.left, 0, handleDataChange('back', 'IC', 'heelEversion', 'left'), 6)}
                {createNumberInputGrid(`Heel Eversion, R, 0-10, IC vs MSt`, data.back.IC.heelEversion.right, 0, handleDataChange('back', 'IC', 'heelEversion', 'right'), 6)}

                {createNumberInputGrid(`Toe Out, L, 5-10, MSt`, data.back.MSt.toeOutAngle.left, 0, handleDataChange('back', 'MSt', 'toeOutAngle', 'left'), 6)}
                {createNumberInputGrid(`Toe Out, R, 5-10, MSt`, data.back.MSt.toeOutAngle.right, 0, handleDataChange('back', 'MSt', 'toeOutAngle', 'right'), 6)}

                {createNumberInputGrid(`Vertical Displacement, L, 0-8, MSt vs MSw`, data.back.MSt.verticalDisplacement.left, 0, handleDataChange('back', 'MSt', 'verticalDisplacement', 'left'), 6)}
                {createNumberInputGrid(`Vertical Displacement, R, 0-8, MSt vs MSw`, data.back.MSt.verticalDisplacement.right, 0, handleDataChange('back', 'MSt', 'verticalDisplacement', 'right'), 6)}

                {createNumberInputGrid(`Heel Height, L, 90+, MSt`, data.back.MSt.heelHeight.left, 0, handleDataChange('back', 'MSt', 'heelHeight', 'left'), 6)}
                {createNumberInputGrid(`Heel Height, R, 90+, MSt`, data.back.MSt.heelHeight.right, 0, handleDataChange('back', 'MSt', 'heelHeight', 'right'), 6)}

                {createNumberInputGrid(`Heel Whip, L, 0-20, MSw`, data.back.MSw.heelWhipAngle.left, 0, handleDataChange('back', 'MSw', 'heelWhipAngle', 'left'), 6)}
                {createNumberInputGrid(`Heel Whip, R, 0-20, MSw`, data.back.MSw.heelWhipAngle.right, 0, handleDataChange('back', 'MSw', 'heelWhipAngle', 'right'), 6)}

                {createNumberInputGrid(`Base of Support, 5+, MSt`, data.back.MSt.baseOfSupport, 0, handleDataChange('back', 'MSt', 'baseOfSupport'), 12)}
                {createNumberInputGrid(`Knee Window, 8+, MSt`, data.back.MSt.kneeWindow, 0, handleDataChange('back', 'MSt', 'kneeWindow'), 12)}
              </Grid>
            </fieldset>
          </>
        )}

        {videoAngle && (
          <>
            <fieldset>
              <legend>Other metrics</legend>
              {createNumberInput('Cadence (steps per min)', data.cadence || '', 0, handleCadenceChange)}
              <FormControl sx={{mt:1}} size="small" variant="outlined" fullWidth>
                <InputLabel id="treadmill-shaking-degree-label">Treadmill Shaking Degree</InputLabel>
                <Select
                  labelId="treadmill-shaking-degree-label"
                  value={data.treadmillShakingDegree || ''}
                  onChange={handleTreadmillShakingChange}
                >
                  <MenuItem value="">
                    <em>--Select a degree--</em>
                  </MenuItem>
                  {[0, 1, 2, 3, 4, 5].map((degree) => (
                    <MenuItem key={degree} value={degree}>{degree}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </fieldset>
            <ReviewHighlights highlights={highlights} setHighlights={setHighlights} />
            <ReviewTips tips={tips} setTips={setTips} />
          </>
        )}
        <Button sx={{mt:1}}
          variant="contained"
          color="primary"
          type="submit"
          disabled={!areTimestampsSet() || isUploading}
          fullWidth>
          {isUploading ? <CircularProgress size={24} /> : "Mark as Reviewed"}
        </Button>
        <Dialog
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
          maxWidth="md"
          fullWidth
        >
          <img
            src={currentImage}
            alt="Zoomed video frame"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Dialog>

      </Box>
    </form>
  );
};

export default ReviewSubmissionValidForm;
